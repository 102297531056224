<template>
  <div>
    <a-modal
      width="1220px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑客户套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="12">
              <span>基本信息</span>
              <a-form-item label="套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="基础套餐名称"
                class="simiot-display-item"
              >
                {{ data.base_product_name }}
              </a-form-item>

              <a-form-item
                label="套餐周期"
                class="simiot-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="simiot-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="simiot-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <a-form-item label="套餐描述：">
                <a-textarea
                  v-decorator="['product_description', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <div v-show="isCanSetAllowRetailOrSelfSubscribe">
                <span>零售设置</span>

                <a-form-item label="允许零售">
                  <a-checkbox
                    :checked="isAllowRetail"
                    v-decorator="['is_allow_retail']"
                    @change="changeIsAllowRetail"
                  /> 允许移动端订购
                </a-form-item>

                <a-form-item label="零售价设置者" v-show="isAllowRetail">
                  <a-radio-group
                    disabled
                    v-decorator="['retail_operator',{
                      initialValue: data.retail_operator,
                      rules: [
                        { required: isAllowRetail, message: '请选择零售价设置者' },
                      ]
                    }]"
                  >

                    <a-radio value="platform">
                      平台
                    </a-radio>
                    <a-radio value="user">
                      零售商
                    </a-radio>
                  </a-radio-group>
                  <font style="color:red;">(继承自基础套餐)</font>
                </a-form-item>

                <span v-show="false">自定义功能</span>

                <a-form-item label="限制订购周期数" v-show="false">
                  <a-input-group compact>
                    <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
                      <a-checkbox
                        :checked="isLimitSubscribeCycle"
                        v-decorator="['is_limit_subscribe_cycle']"
                        @change="changeIsLimitSubscribeCycle"
                      />
                    </a-form-item>

                    <a-form-item
                      :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
                      v-if="isLimitSubscribeCycle"
                    >
                      <a-space>
                        <a-input-number
                          style="width: 200px"
                          :min="1"
                          :max="10000000"
                          :step="1"
                          :precision="0"
                          v-decorator="['limit_subscribe_cycle', {
                            initialValue: data.limit_subscribe_cycle == -1 ? undefined : data.limit_subscribe_cycle,
                            rules: [
                              { required: isLimitSubscribeCycle, message: '请输入订购周期数' }
                            ]
                          }]"
                        />
                        <span>个周期</span>
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-form-item>
              </div>

              <div v-show="isCanSetAllowRetailOrSelfSubscribe">
                <span>其它设置</span>
                <a-form-item label="允许自订">
                  <a-checkbox
                    :checked="isAllowSelfSubscribe"
                    v-decorator="['is_allow_self_subscribe']"
                    @change="changeIsAllowSelfSubscribe"
                  /> 允许CMP订购
                </a-form-item>
              </div>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span>价格设置</span>

              <a-row
                :gutter="24"
                style="padding-left: 2px"
              >
                <a-col
                  :span="favorablePriceSpanSize"
                  v-for="label in favorablePriceLabels"
                  :key="label"
                >
                  {{ label }}
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(k, index) in form.getFieldValue('keys')"
                :key="k"
              >
                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="1"
                      :max="10000000"
                      style="width: 120px"
                      disabled
                      @change="validateFavorablePriceCycles"
                      v-decorator="[`agents_product_favorable_prices[${k}].cycles`, {
                        initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].cycles,
                        rules: [
                          { required: true, message: '请输入周期数' },
                          { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                          { validator: (rule, value, callback) => checkCycles(rule, value, callback, k, index) }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isSaleLogin || isMerchantSales"
                      v-decorator="[`agents_product_favorable_prices[${k}].floor_price`, {
                        initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].floor_price,
                        rules: [
                          { required: true, message: '请输入底价' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        :min="0"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        :disabled="isMerchantSales"
                        v-decorator="[`agents_product_favorable_prices[${k}].distributor_price`, {
                          initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].distributor_price,
                          rules: [
                            { required: true, message: '请输入客户价' },
                            { validator: checkDistributorPrice }
                          ]
                        }]"
                      />

                      <a-icon
                        v-show="!isPlatformRetailOperator && form.getFieldValue('keys').length > 1 && !isSaleLogin"
                        :style="{ display: 'inline-block'}"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        :disabled="form.getFieldValue('keys').length === 1"
                        @click="() => removeSetPriceItem(k)"
                      />
                    </a-space>
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-show="isPlatformRetailOperator">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :step="0.01"
                          :max="10000000"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`agents_product_favorable_prices[${k}].retail_price`, {
                            initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].retail_price,
                            rules: [
                              { type: 'number', message: '请输入 1 - 10000000 之间的数字' },
                              { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }
                            ]
                          }]"
                        />

                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          @click="() => removeSetPriceItem(k)"
                          :disabled="form.getFieldValue('keys').length === 1"
                          v-show="form.getFieldValue('keys').length > 1 && !isSaleLogin"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsProductForm, updateAgentsProduct, checkAgentsProductPrices } from '@/api/agents_product'

export default {
  name: 'EditAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      excessRateQuantityUnit: 'MB', // 超额计费单位
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      userBusinessType: 'custom_month',
      data: {},
      agentsProductFavorablePrices: {},
      isMerchantSales: false,
      isCanSetAllowRetailOrSelfSubscribe: true, // 是否可设置零售配置
      isAllowRetail: false, // 是否允许零售
      isLimitSubscribeCycle: false, // 是否限制订购周期数
      isAllowSelfSubscribe: false, // 是否允许自定套餐
      favorablePriceLabels: ['周期数*', '底价(元)*', '客户价(元)*'], // 价格设置label
      favorablePriceSpanSize: 8, // 价格设置span大小
      submitting: false,
      spinning: false,
      priceIndex: 1,
      form: this.$form.createForm(this, { name: 'agents_product' }),
      agentsProductsCardTypes: {},
      isPlatformRetailOperator: false, // 零售价设置者是否是平台
      cardTypePriceRequired: [] // 卡片价格必选数组
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    isSaleLogin() {
      return this.$store.getters.userRole === 'sale' || this.$store.getters.userRole === 'sale_assistant'
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchFormData()
  },
  methods: {
    checkDistributorPrice(rule, value, callback) {
      if (this.isSaleLogin && this.form.getFieldValue(`${rule.field.split('.')[0]}.floor_price`) > value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于底价')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCycles(rule, value, callback, k, index) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const preCycleIndex = this.form.getFieldValue('keys')[index - 1]
      const preCycles = this.form.getFieldValue(`agents_product_favorable_prices[${preCycleIndex}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`agents_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`agents_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    fetchFormData() {
      this.spinning = true
      findAgentsProductForm(this.id).then((res) => {
        this.data = res.data
        this.isLimitSubscribeCycle = this.data.is_limit_subscribe_cycle
        this.isAllowRetail = this.data.is_allow_retail
        this.isAllowSelfSubscribe = this.data.is_allow_self_subscribe
        this.isPlatformRetailOperator = this.data.is_allow_retail && this.data.retail_operator === 'platform'

        this.favorablePriceSpanSize = this.isPlatformRetailOperator ? 6 : 8
        if (this.isPlatformRetailOperator) {
          this.favorablePriceLabels.push('零售价*')
        }

        if (this.data.capacity_unit === 'count') {
          this.excessRateQuantityUnit = '次'
        }

        this.userBusinessType = this.data.user_business_type_slug
        if (this.userBusinessType === 'custom_day') {
          this.isShowExcessRateItem = false
        } else if (this.userBusinessType === 'merchant_sales') {
          this.isMerchantSales = true
        }

        this.agentsProductFavorablePrices = res.data.agents_product_favorable_prices
        this.priceIndex = this.agentsProductFavorablePrices.length
        const keys = []
        this.agentsProductFavorablePrices.forEach((value, index) => {
          keys.push(index)
          // 处理零售价值
          if (value.retail_price === -1) {
            value.retail_price = undefined
          }
        })
        this.form.setFieldsValue({
          keys: keys
        })
        // const keys = []
        // for (let i = 0; i < this.agentsProductFavorablePrices.length; i++) {
        //   keys.push(i)
        // }

        this.form.setFieldsValue({
          name: this.data.name,
          is_allow_retail: this.data.is_allow_retail,
          retail_operator: this.data.retail_operator,
          is_allow_self_subscribe: this.data.is_allow_self_subscribe,
          is_limit_subscribe_cycle: this.data.is_limit_subscribe_cycle,
          product_description: this.data.product_description,
          excess_rate_quantity: this.data.excess_rate_quantity,
          excess_distributor_price: this.data.excess_distributor_price,
          excess_floor_price: this.data.excess_floor_price
        })

        this.spinning = false
      })
    },

    // 删除价格设置
    removeSetPriceItem(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      this.validateFavorablePriceCycles()
    },

    checkRetailPrice(rule, value, callback, k) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const distributorPrice = this.form.getFieldValue(`agents_product_favorable_prices[${k}].distributor_price`)
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于客户价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 改变是否允许零售
    changeIsAllowRetail(e) {
      if (e.target.checked) {
        this.isAllowRetail = true
      } else {
        this.isAllowRetail = false
      }
    },

    // 改变是否允许自订
    changeIsAllowSelfSubscribe(e) {
      if (e.target.checked) {
        this.isAllowSelfSubscribe = true
      } else {
        this.isAllowSelfSubscribe = false
      }
    },

    // 改变是否限制订购周期数
    changeIsLimitSubscribeCycle(e) {
      this.isLimitSubscribeCycle = e.target.checked
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            agents_product_favorable_prices: values.agents_product_favorable_prices.filter(key => key)
          }

          checkAgentsProductPrices(this.id,
            { agents_product_favorable_prices: data.agents_product_favorable_prices }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                this.updateProduct(data, this)
              } else {
                const vm = this
                this.$confirm({
                  title: '设置的客户价中存在大于同周期节点下(分销商的企业客户价格)的情况，确定保存吗?',
                  content: '',
                  okText: '确定',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk() {
                    vm.updateProduct(data, vm)
                  }
                })
              }
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    },

    updateProduct(data, _this) {
      updateAgentsProduct(_this.id, data).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          _this.isShow = false
          // 告知父组件已完成
          _this.$emit('completed')
        }
      })
    }
  }
}
</script>
